/**
 * Exports flashMessageStateInit function that returns an object with keys described in
 * FlashMessageViewInterface. flashMessageStateInit is primarily used as object in alpine
 * js's x-data directive.
 *
 * In order to add flash message explicitly, one should dispatch a custom event flash with detail
 * containing an object with keys defined in FlashMessage except for id and title (optional).
 * For example,
 * window.dispatchEvent(
 *   new CustomEvent(
 *     'flash',
 *     {detail: {type: 'info', message: 'Sample Message', title: 'Sample Title'}}
 *   )
 * )
 */

enum FlashMessageType {
  Warning = "warning",
  Error = "error",
  Success = "success",
  Info = "info",
}

interface FlashMessage {
  id: number;
  type: string;
  message: string;
  title: string;
}

interface FlashMessageViewInterface {
  flashMessageId: number;
  flashMessages: FlashMessage[];
  showFlashMessages: boolean;
  removeFlashMessage(id: number): void;
  addFlashMessage(type: string, message: string, title: string): void;
  render({}: FlashMessage, $dispatch: any): string;
}

function getFeatherClass(type: string): string {
  let className;
  switch (type) {
    case FlashMessageType.Warning:
      className = "alert-circle";
      break;
    case FlashMessageType.Error:
      className = "alert-circle";
      break;
    case FlashMessageType.Success:
      className = "check-circle";
      break;
    case FlashMessageType.Info:
      className = "check-circle";
      break;
    default:
      className = "";
  }
  return className;
}

function getIconClass(type: string): string {
  let className;
  switch (type) {
    case FlashMessageType.Warning:
      className = "text-warning";
      break;
    case FlashMessageType.Error:
      className = "";
      break;
    case FlashMessageType.Success:
      className = "text-success";
      break;
    case FlashMessageType.Info:
      className = "text-info";
      break;
    default:
      className = "";
  }
  return className;
}

function getTitle(type: string): string {
  let title;
  switch (type) {
    case FlashMessageType.Warning:
      title = "This is just a warning!";
      break;
    case FlashMessageType.Error:
      title = "Something went wrong!";
      break;
    case FlashMessageType.Success:
      title = "Tasks successful!";
      break;
    case FlashMessageType.Info:
      title = "Important information!";
      break;
    default:
      title = "";
  }
  return title;
}

const flashMessageStateInit = (): FlashMessageViewInterface => ({
  flashMessageId: 0,
  flashMessages: [],
  showFlashMessages: false,
  render({ id, type, message, title }: FlashMessage) {
    return `
    <div class="alert alert-${type} flex items-center w-full md:w-138 py-4 px-6 mb-5 justify-between" x-data x-init="setTimeout(() => {$dispatch('remove-flash', {id: ${id}})}, 4000)">
      <i data-feather="${getFeatherClass(type)}" class="${getIconClass(
        type,
      )} text-2xl mr-4"></i>
      <div class="flex flex-wrap leading-6 text-base -mx-1 md:w-108">
        <div class="font-semibold px-1">${title}</div>
        <div class="font-normal px-1">${message}</div>
      </div>
      <div class="cursor-pointer text-base ml-4" x-on:click="$dispatch('remove-flash', {id: ${id}})">&times;</div>
    </div>
    `;
  },
  addFlashMessage(type: string, message: string, title: string): void {
    const validType = (<any>Object).values(FlashMessageType).includes(type);

    if (validType && message !== "" && message !== null) {
      const setTitle = title ? title : getTitle(type);
      const id = this.flashMessageId;
      const flashMessages: FlashMessage[] = this.flashMessages;
      flashMessages.unshift({
        id: id,
        type: type,
        message: message,
        title: setTitle,
      });
      this.showFlashMessages = true;
      this.flashMessageId++;

      if (this.flashMessages.length > 6) {
        this.flashMessages.pop();
      }
    } else if (!validType) {
      console.log(`${type} is not a valid type`);
    } else {
      console.log("message cannot be blank");
    }
  },
  removeFlashMessage(id: number): void {
    this.flashMessages = this.flashMessages.filter(
      (flashMessage: FlashMessage): boolean => flashMessage.id !== id,
    );
    if (this.flashMessages.length === 0) {
      this.showFlashMessages = false;
    }
  },
});

export default flashMessageStateInit;
