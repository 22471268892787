import Alpine from "alpinejs";
import select2Selectors from "./views/form_helpers/select2_selectors";
import flashMessageStateInit from "./components/FlashMessage";
import tomSelectInit from "./components/tom-select";

import "./components/global_jquery.js";
import "./components/plausible.js";

// Include phoenix_html to handle method=PUT/DELETE in forms and buttons.
import "phoenix_html";

declare var window: any;

if (typeof window.queueMicrotask !== "function") {
  window.queueMicrotask = (callback: any) => {
    Promise.resolve()
      .then(callback)
      .catch((e) =>
        setTimeout(() => {
          throw e;
        }),
      );
  };
}

window.Alpine = Alpine;

Alpine.data("flashMessage", flashMessageStateInit as any);

Alpine.start();

function handleDOMContentLoaded() {
  const forms = document.querySelectorAll(
    "form input, form select, form textarea",
  );

  forms.forEach((form) => {
    form.addEventListener("change", () => {
      form.classList.add("form-dirty");
    });

    window.addEventListener("beforeunload", () => {
      if (
        !form.classList.contains("ignore-changes") &&
        form.classList.contains("form-dirty")
      ) {
        return "You have unsaved changes, are you sure you want to discard them?";
      }
    });

    form.addEventListener("submit", () => {
      form.classList.remove("form-dirty");
    });
  });

  const feather = require("feather-icons");
  feather.replace();
  select2Selectors();
  tomSelectInit();
}

function handleDocumentUnload() {
  window.currentView.unmount();
}

window.addEventListener("DOMContentLoaded", handleDOMContentLoaded, false);
window.addEventListener("unloaded", handleDocumentUnload, false);
