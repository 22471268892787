// Module for initializing tom-select for dead views
// Calling initTomSelect() on liveviews has no effect

import TomSelect from "tom-select";

export default function tomSelectInit(selector = "select[data-options]") {
  if (isLiveView()) return;

  document.querySelectorAll(selector).forEach((el: any) => {
    new TomSelect(el, { plugins: ["dropdown_input", "no_backspace_delete"] });
  });
}

function isLiveView() {
  return !!document.querySelector("[data-phx-main]");
}
